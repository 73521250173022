// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-creators-index-js": () => import("./../../../src/pages/creators/index.js" /* webpackChunkName: "component---src-pages-creators-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podstronauts-index-js": () => import("./../../../src/pages/podstronauts/index.js" /* webpackChunkName: "component---src-pages-podstronauts-index-js" */),
  "component---src-pages-problems-index-js": () => import("./../../../src/pages/problems/index.js" /* webpackChunkName: "component---src-pages-problems-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-creator-js": () => import("./../../../src/templates/creator.js" /* webpackChunkName: "component---src-templates-creator-js" */),
  "component---src-templates-problem-js": () => import("./../../../src/templates/problem.js" /* webpackChunkName: "component---src-templates-problem-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */)
}

